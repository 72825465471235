import { default as indexKpoHMVHHjjMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/index.vue?macro=true";
import { default as packages9WcwbW9ab4Meta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsVwXPxwuG9GMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/parts.vue?macro=true";
import { default as custommx0yhgYFZYMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom.vue?macro=true";
import { default as indexVlU51poMPSMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/index.vue?macro=true";
import { default as rates66VsZWLxBbMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionfEuNa1aSTRMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/subscription.vue?macro=true";
import { default as accountdxxaxHO9MbMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account.vue?macro=true";
import { default as _91id_93UrzEahxOsIMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexHrVDzvipl5Meta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93fnhMtlfol0Meta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93Jh0UXwJTcTMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexdWDAGBkOIgMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesbOBhiS8uf1Meta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93FyioTzESAAMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexHeUeAv3NxAMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/index.vue?macro=true";
import { default as productsV8bAlq3332Meta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/products.vue?macro=true";
import { default as index8dP3gacDzIMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/index.vue?macro=true";
import { default as configurationmUhbm9kOlZMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexmlaii9ElXOMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as plane63N8VhKLWMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksalIEljmEOzMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenanceMIR3Lvw72VMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsn0BYGsjqbRMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/manuals.vue?macro=true";
import { default as timespGxHci5eYuMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/times.vue?macro=true";
import { default as catalogRDWF0in1tKMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog.vue?macro=true";
import { default as commandedLfyhFtywEMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/commande.vue?macro=true";
import { default as _91tab_93Fuw8y6SCqvMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93VHnDDWnXfrMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93fVyMGu7vznMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf4zQFZWwYGIMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexK72ne7zP5VMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/index.vue?macro=true";
import { default as loginBecNMMgeMXMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/login.vue?macro=true";
import { default as panierqyEosx9g6gMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/panier.vue?macro=true";
import { default as indexnHNvzue4voMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsTbB5qeQF1CMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueszHrKanedIMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique.vue?macro=true";
import { default as maintenancePC8I38ES2KMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/print/maintenance.vue?macro=true";
import { default as products7jk67GvOsnMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/products.vue?macro=true";
import { default as signup5yWtRu1rPKMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordapNL94Gy0RMeta } from "/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountdxxaxHO9MbMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account.vue"),
    children: [
  {
    name: custommx0yhgYFZYMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/Boutique/products.vue")
  },
  {
    name: catalogRDWF0in1tKMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/index.vue")
  },
  {
    name: maintenanceMIR3Lvw72VMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf4zQFZWwYGIMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginBecNMMgeMXMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/panier.vue")
  },
  {
    name: pneumatiqueszHrKanedIMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenancePC8I38ES2KMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup5yWtRu1rPKMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/apa-trading.fr/dev-app.apa-trading.fr/pages/tableau-de-bord.vue")
  }
]